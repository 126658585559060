import {Component, OnInit} from '@angular/core';
import {ProductsService} from '../../services/products.service';
import {ProductModel} from '../../classes/product.model';
import {NavigationEnd, Router} from '@angular/router';
import {trackByFn} from '@ft/core';
import {debounce, filter, tap} from 'rxjs/operators';
import {merge, timer} from 'rxjs';

@Component({
    selector: 'ftm-products-main',
    templateUrl: './main.component.html',
    styleUrls: [
        './main.component.scss'
    ]
})
export class ProductsMainComponent implements OnInit {
    public products: ProductModel[] = [];
    public trackByFn = trackByFn('id');

    constructor(private service: ProductsService, private router: Router) {
    }

    ngOnInit() {
        this.routerEvents
            .subscribe(() => {
                return this.router.url == '/products' ? this.router.navigateByUrl(this.products[0].path) : null;
            });
    }

    private get routerEvents() {
        const routerEvent = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        return merge(routerEvent, this.service.products$)
            .pipe(
                debounce(() => timer(0)),
                tap(() => this.products = this.service.products$.getValue())
            );
    }

}
