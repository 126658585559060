import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {merge, Observable, Subscription, timer} from 'rxjs';
import {debounce, distinctUntilChanged, filter, map, mergeMap} from 'rxjs/operators';
import {ProductModel} from '../../classes/product.model';
import {ProductsService} from '../../services/products.service';
import {HEIGHT_ANIMATION, SMALL_DIALOG, trackByFn} from '@ft/core';
import {assign, isNil} from 'lodash-es';
import {MatDialog} from '@angular/material/dialog';
import {ProductUpdateDialog} from '../../dialogs/product-update/product-update.dialog';
import {CertificateDialog} from '../../dialogs/certificate/certificate.dialog';
import {fromPromise} from 'rxjs/internal-compatibility';

@Component({
    selector: 'ftm-product-detail',
    templateUrl: './detail.component.html',
    animations: [
        HEIGHT_ANIMATION
    ],
    styleUrls: ['./detail.component.scss']
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    public product: ProductModel;
    public trackByFn = trackByFn('label');

    private subscription: Subscription;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private service: ProductsService
    ) {
    }

    get hasProduct() {
        return !isNil(this.product);
    }

    private get _routerEvents(): Observable<ProductModel> {
        const routerParams = this.route.params;
        const routerEvent = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        return merge(routerEvent, routerParams, this.service.products$)
            .pipe(
                debounce(() => timer(0)),
                map(() => this.service.products$.getValue().find(
                    item => item.id === parseInt((this.route.params as any).value.id, null)
                ))
            );
    }

    ngOnInit() {
        this.subscription = this._routerEvents
            .pipe(
                filter(product => this.router.url.startsWith(product.path)), distinctUntilChanged(),
                mergeMap((product: ProductModel) => this._navigateObservable(product))
            )
            .subscribe(product => {
                this.product = product;
                this.service.currentProduct$.next(product);
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    updateProduct() {
        const dialogRef = this.dialog.open(ProductUpdateDialog, assign(SMALL_DIALOG, {
            data: {product: this.product},
        }));

        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.product = data;
                    this.service.currentProduct$.next(data);
                }
            }
        );
    }

    updateCertificate() {
        this.dialog.open(CertificateDialog, SMALL_DIALOG);
    }

    private _navigateObservable(product: ProductModel): Observable<ProductModel> {
        return fromPromise(this.router.navigate([product.defaultLink.path], {
            queryParams: product.defaultLink.params, relativeTo: this.route
        })).pipe(map(() => product));
    }
}
